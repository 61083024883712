/** @jsx jsx */
import { useState } from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { jsx, Grid, Box, Heading, Text } from "theme-ui";
import ResizeObserver from "react-resize-observer";

import Layout from "../components/layout";
// import Nav from "../components/nav";
// import SidePanel from "../components/side-panel";
import { Slideshow } from "../components/slideshow";
import BreadCrumbs from "../components/breadcrumbs";

const Product = ({ pageContext, location, data }) => {
  const [width, setWidth] = useState(null);
  // let post, siteTitle, previous, next;
  // if (this.props.preview) {
  //   post = this.props.preview;
  //   siteTitle = "Preview";
  //   previous = null;
  //   next = null;
  // } else {
  //   post = this.props.data.wpgraphql.postBy;
  //   siteTitle = this.props.data.site.siteMetadata.title;
  //   ({ previous, next } = this.props.pageContext);
  // }

  // adapt for preview -> different graphql data
  const { title, content, furniture } = data?.wpPost || data?.preview || {};
  const images =
    furniture?.obrazky?.map(x => ({
      fluid: x?.localFile.childImageSharp.fluid,
    })) || [];
  // console.log(data);
  const seo = {
    metaTitle: title,
    // metaDescription: description,
    // shareImage: images[0].url,
    // article: true,
  };

  const { crumbs } = pageContext?.breadcrumb || {};

  return (
    <Layout seo={seo}>
      <ResizeObserver
        onResize={rect => {
          // console.log("Resized. New bounds:", rect.width, "x", rect.height);
          // console.log("Resized. New bounds:", window.innerWidth, "x", window.innerHeight);
          setWidth(rect.width);
        }}
        // onPosition={rect => {
        //   console.log("Moved. New position:", rect.left, "x", rect.top);
        // }}
      />
      {/* <Nav /> */}

      <Box sx={{ minHeight: "87vh" }}>
        <Grid gap={2} columns={[1, 1, 1, 1, 2, "2fr 3fr"]}>
          {/* <SidePanel setSearchResult={setSearchResult} /> */}
          <Box px={2}>
            <BreadCrumbs data={crumbs} />
            <Slideshow productView images={images} width={width} />
          </Box>

          <Box px={2}>
            <Heading>{title}</Heading>
            <Text
              mr="3rem"
              sx={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {/* <Description>{content}</Description> */}
            {/* specification */}
            {/* TODO: buttons to ask for similar offer and share on FB, instagram */}
          </Box>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Product;

export const query = graphql`
  query ProjectQuery($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      title
      content
      furniture {
        fieldGroupName
        rozpocet
        sirka
        tvarNabytku
        tvarNabytkuSkrina
        typNabytku
        vyska
        hlbka {
          fieldGroupName
          kuchynaDolnaSkrinka
          kuchynaHornaSkrinka
          skrina
        }
        moznostiNabytku {
          fieldGroupName
          kuchyna
          sedacka
          skrina
        }
        kovanie {
          fieldGroupName
          kuchyna
          skrina
        }
        obrazky {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        prevedenie {
          fieldGroupName
          kuchyna
          postel
          sedacka
          skrina
        }
      }
    }
  }
`;
